<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/supervisors">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
            <form id="add-supervisor-form" class="needs-validation" autocomplete="off" @submit.prevent="tooltipForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الأسم الكامل</label>
                    <input v-model="tooltipform.name" type="text" class="form-control" placeholder="الأسم الكامل" :class="{
                      'is-invalid': submitform && $v.tooltipform.name.$error,
                    }" />
                    <div v-if="submitform && $v.tooltipform.name.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.name.required">
                        الاسم الكامل مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">البريد الالكتروني</label>
                    <input v-model="tooltipform.login_email" type="text" class="form-control" autocomplete="off"
                      placeholder="البريد الالكتروني" :class="{
                        'is-invalid': submitform && $v.tooltipform.login_email.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.login_email.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.login_email.required">
                        البريد الالكتروني مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">كلمة المرور</label>
                    <input v-model="tooltipform.password" type="password" class="form-control" placeholder="كلمة المرور" autocomplete="new-password"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label">الصلاحية</label>
                    <select v-model="tooltipform.role_id" class="form-control" :class="{
                      'is-invalid': submitform && $v.tooltipform.role_id.$error,
                    }">
                      <option value="">يرجى الاختيار</option>
                      <option v-for="role in roles" :value="role.id" :key="role.id">
                        {{ role.role_name }}
                      </option>

                    </select>
                    <div v-if="submitform && $v.tooltipform.role_id.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.role_id.required">
                        الصلاحية مطلوبة.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary float-right mt-3" type="submit">
                {{ $t('save') }}
                <i class="mdi mdi-content-save-outline"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Supervisor modification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      title: "Supervisor modification",
      roles: [],
      tooltipform: {
        name: "",
        login_email: "",
        password: "",
        role_id: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    tooltipform: {
      name: { required },
      login_email: { required },
      role_id: { required },
    },
  },
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    Jquery() {
      $(document).on('submit', '#add-supervisor-form', function () {
        if (!that.$v.$anyError) {
          $(this).find(":submit").attr('disabled', 'disabled');
          let form = new FormData();
          form.append('name', that.tooltipform.name);
          form.append('login_email', that.tooltipform.login_email);
          form.append('password', that.tooltipform.password);
          form.append('role_id', that.tooltipform.role_id);
          form.append('lang', that.$i18n.locale);
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          };
          var url = `${that.$appUrl}/api/admin/supervisors/update/${that.$route.params.id}`;
          that.axios.post(url, form, { headers })
            .then(response => {
              if (response.data.status == 1) {
                that.$toast.open({
                  message: response.data.message,
                  type: 'success',
                  position: 'top-left',
                  duration: 2500
                });
                setTimeout(() => {
                  that.$router.push(`/supervisors`);
                }, 1000);
              } else {
                $(this).find(":submit").prop('disabled', false);
                that.$toast.open({
                  message: response.data.message,
                  type: 'error',
                  position: 'top-left',
                  duration: 2500
                });
              }
            });
        }
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    var url = `${this.$appUrl}/api/admin/supervisors/create`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.roles = response.data.roles;
    });
    $('.page-loader-parent').show();
    $('#add-supervisor-form').hide();
    var url = `${this.$appUrl}/api/admin/supervisors/edit/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.tooltipform.name = response.data.admin.name;
      this.tooltipform.login_email = response.data.admin.login_email;
      this.tooltipform.role_id = response.data.admin.role_id;
      $('.page-loader-parent').remove();
      $('#add-supervisor-form').show();
    });
  }
};
</script>
<style lang="scss" scoped>
.image-container {
  border: 2px dashed #e0e0e0;
  border-radius: 3px;
  width: 100%;
  margin-top: 2%;
  min-height: 150px;
  cursor: pointer;

  img {
    display: block;
    margin: 0px auto;
    max-width: 385px;
    padding: 30px 0px;
  }
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  padding-top: 5px;
  font-weight: 600;
}

.open .dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 0px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

li {
  display: flex;
}

.dropdown-menu>li>a {
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #000 !important;
  white-space: nowrap;
}

.country-code-sign::-webkit-scrollbar {
  width: 3px;
}

.country-code-sign::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e3e3e3;
  border-radius: 25px;
}

.country-code-sign::-webkit-scrollbar-thumb {
  background: #196dfb;
  border-radius: 25px;
}

.country-code-sign::-webkit-scrollbar-thumb:hover {
  background: #196dfb;
}

.code {
  padding-left: 4px;
  vertical-align: text-bottom;
}

input[type="number"],
input[type="date"],
input[type="password"],
input[type="text"],
select {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0;
  min-height: 2.375rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}
</style>
<style>
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.card:has(.page-loader-parent) {
  min-height: 350px;
}
</style>