var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"page-title-box"},[_c('h4',{staticClass:"font-size-18"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"float-end d-none d-md-block"},[_c('router-link',{attrs:{"to":"/supervisors"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" "+_vm._s(_vm.$t('back'))+" "),_c('i',{staticClass:"mdi mdi-keyboard-backspace"})])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 page-loader-parent"},[_c('div',{staticClass:"page-loader"},[_c('md-progress-spinner',{attrs:{"md-diameter":50,"md-stroke":5,"md-mode":"indeterminate"}})],1)])]),_c('form',{staticClass:"needs-validation",attrs:{"id":"add-supervisor-form","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.tooltipForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationTooltip01"}},[_vm._v("الأسم الكامل")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tooltipform.name),expression:"tooltipform.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitform && _vm.$v.tooltipform.name.$error,
                  },attrs:{"type":"text","placeholder":"الأسم الكامل"},domProps:{"value":(_vm.tooltipform.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tooltipform, "name", $event.target.value)}}}),(_vm.submitform && _vm.$v.tooltipform.name.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.tooltipform.name.required)?_c('span',[_vm._v(" الاسم الكامل مطلوب. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationTooltip01"}},[_vm._v("البريد الالكتروني")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tooltipform.login_email),expression:"tooltipform.login_email"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.tooltipform.login_email.$error,
                    },attrs:{"type":"text","autocomplete":"off","placeholder":"البريد الالكتروني"},domProps:{"value":(_vm.tooltipform.login_email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tooltipform, "login_email", $event.target.value)}}}),(_vm.submitform && _vm.$v.tooltipform.login_email.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.tooltipform.login_email.required)?_c('span',[_vm._v(" البريد الالكتروني مطلوب. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationTooltip01"}},[_vm._v("كلمة المرور")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tooltipform.password),expression:"tooltipform.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"كلمة المرور","autocomplete":"new-password"},domProps:{"value":(_vm.tooltipform.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tooltipform, "password", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{staticClass:"form-label"},[_vm._v("الصلاحية")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tooltipform.role_id),expression:"tooltipform.role_id"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitform && _vm.$v.tooltipform.role_id.$error,
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tooltipform, "role_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("يرجى الاختيار")]),_vm._l((_vm.roles),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(" "+_vm._s(role.role_name)+" ")])})],2),(_vm.submitform && _vm.$v.tooltipform.role_id.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.tooltipform.role_id.required)?_c('span',[_vm._v(" الصلاحية مطلوبة. ")]):_vm._e()]):_vm._e()])])]),_c('button',{staticClass:"btn btn-primary float-right mt-3",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" "),_c('i',{staticClass:"mdi mdi-content-save-outline"})])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }